import React from 'react'
import { graphql } from "gatsby"
import { Culture } from "../../../components/recruit/culture"


const breadcrumbsData: { text: string; path: string }[] = [
    { text: `RECRUIT`, path: `recruit` },
    { text: `カルチャー`, path: `recruit/culture` },
]

export default function CulturePage({ data }) {
  const {
    headline,
  } = data
  return (
    <Culture
      headerImg={headline.childImageSharp.fluid}
      breadcrumbsData={breadcrumbsData}
    />
  )
}

export const query = graphql`
  query CultureQuery {
    headline: file(relativePath: { eq: "images/recruit/img_recruit_culture_header.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`