import React from 'react'
import styled from "styled-components"

import { TitleAndCaption } from "../common/TitleAndCaption";
import { DetailCard } from './DetailCard';
import { DetailLongCard } from './DetailLongCard';
import { RoundLinkButton } from '../../common/RoundLinkButton';

import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"

import nextcoin from "../../../images/recruit/icon_nextcoin.svg"
import lunch from "../../../images/recruit/icon_lunch.svg"
import mokumoku from "../../../images/recruit/icon_mokumoku.svg"
import morningSession from "../../../images/recruit/icon_morning_session.svg"
import mtg from "../../../images/recruit/icon_mtg.svg"

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 160px 20px;
  box-sizing: border-box;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const CardAreaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 1080px;
  margin: 80px auto 0px;
  gap: 40px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 40px auto 0px;
  }
`

const ButtonArea = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 80px auto 0;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    max-width: 280px;
    margin: 40px auto 0;
  }
`

type DetailCardItem = {
  icon?: string;
  title?: string;
  text?: string;
}

const DetailCardItem: DetailCardItem[] = [
  {
    icon: nextcoin,
    title: `ネクストコイン`,
    text:  `「ありがとう」簡単な言葉ですが、照れ臭くて言えないときってありますよね。
    私たちは小さなことでも「ありがとう」を伝えることを大切にしています。
    独自に構築したNextCoinという仮想通貨を、メッセージ付きでメンバー間で贈り合っています。
    相手からもらったコインは、賞与に還元されるというダブルで幸せになれる制度です。`,
  },
  {
    icon: lunch,
    title: `歓迎ランチ`,
    text: `入社後、1か月間行われる少人数制の歓迎ランチ会です。ランチ代は会社負担！期間内は何度でも利用できます。`,
  },
  {
    icon: morningSession,
    title: `Good&New`,
    text: `毎朝ランダムで選ばれるメンバーで、24時間以内にあった「良かったこと」や「新しい発見」をシェアします。
    ポジティブな気持ちで業務をスタートでき、仲間のことを知る機会にもなります。`,
  },
  {
    icon: mokumoku,
    title: `LT会`,
    text: `若手メンバーを中心に、技術・知識の研鑽やアウトプットの場として不定期で開催されています。`,
  },
  {
    icon: mtg,
    title: `全社ミーティング`,
    text: `4半期ごとに全社員でミーティングを行います。現状の課題や未来の展望の共有、自分たちが何を成したいかを定期的に整理し共有しています。`,
  },
]

export const InHouseSystem: React.VFC = () => {
  return (
    <Wrapper>
      <TitleAndCaption title="社内制度" subTitle="IN-HOUSE-SYSTEM" />
      <CardAreaWrapper>
        {DetailCardItem.map((item, idx) => {
          return (
            <DetailCard
              icon={item.icon}
              title={item.title}
              text={item.text}
              key={idx}
            />
          )
        })}
      </CardAreaWrapper>
      <ButtonArea>
        <RoundLinkButton title='社内制度についてもっと知る' url='/recruit/welfare'/>
      </ButtonArea>
    </Wrapper>
  )
}
