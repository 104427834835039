import styled from 'styled-components'
import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"

const { ICON_CARD_RESPONSIVE_WIDTH: ICON_CARD_RESPONSIVE_WIDTH } = RECRUIT_PAGE_STYLES;

const Wrapper = styled.div`
  width: 456px;
  height: 960px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: auto;
  padding-bottom: 48px;
  box-sizing: border-box;
  @media(max-width: ${ICON_CARD_RESPONSIVE_WIDTH}px){
    width: 280px;
    height: 584px;
  }
  & .icon {
    width: auto;
    margin-top: 48px;
    @media(max-width: ${ICON_CARD_RESPONSIVE_WIDTH}px){
      margin-top: 16px;
      max-width: 96px;
      max-height: 96px;
    }
  }
`

const Title = styled.h3`
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #222222;
`

const Text = styled.p`
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #222222;
`

export const DetailCardStyle = {
  Wrapper: Wrapper,
  Title: Title,
  Text: Text,
}
