import React from 'react'
import styled from 'styled-components'
import { RECRUIT_PAGE_STYLES, RESPONSIVE_STYLES } from "../../../AppConst"
import { DetailCardStyle } from './DetailCardStyle';

const { ICON_CARD_RESPONSIVE_WIDTH: ICON_CARD_RESPONSIVE_WIDTH } = RECRUIT_PAGE_STYLES;

type Props = {
  icon?: string
  title?: string
  text?: string
}

export const DetailCard: React.VFC<Props> = (props) => {

  const { Title, Text } = DetailCardStyle;

  const Wrapper = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 520px;
    min-height: 240px;

    padding: 24px;
    box-sizing: border-box;

    background-color: #E9E9E9;

    &:first-of-type {
      max-width: 100%;
    }

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      flex-direction: column;
      padding: 16px;
      gap: 15px;
    }

    & .icon {
      width: 120px;

      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      }
    }
  `

  const Content = styled.div`
    display: flex;
    flex-direction: column;
  `

  const ResultWrapper = styled.div`
    width: 100%;
    max-width: 250px;
    height: 192px;

    padding: 31px 40px;
    box-sizing: border-box;
    background-color: #FFFFFF;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      max-width: none;
      padding: 16px;
      height: auto;
    }
  `

  const ResultTextWrapper = styled.div`
    text-align: left;

    & .second {
      margin-top: 16px;
      @media(max-width: ${ICON_CARD_RESPONSIVE_WIDTH}px){
      }
    }

    & .topic {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      letter-spacing: 0.05em;
      text-decoration-line: underline;

      color: #222222;
      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      }
    }
    & .results {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      letter-spacing: 0.05em;
      text-transform: capitalize;

      color: #222222;

      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      }
    }
    & .unit {
      margin-left: 4px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.05em;

      color: #222222;
      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      }
    }
  `

  return (
    <Wrapper className='card-wrapper'>
      <img className='icon' src={props.icon} alt="culture"/>
      <Content>
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>
      </Content>
      { props.title == "ネクストコイン" &&
        <ResultWrapper>
          <ResultTextWrapper>
            <div className='topic'>年間流通回数</div>
            <span className='results'>34,561</span>
            <span className='unit'>回</span>
          </ResultTextWrapper>
          <ResultTextWrapper>
            <div className='topic second'>年間流通コイン</div>
            <span className='results'>889,990</span>
            <span className='unit'>コイン</span>
          </ResultTextWrapper>
        </ResultWrapper>
      }
    </Wrapper>
  )
}
