import React from 'react'
import styled from "styled-components"
import { PointCircle } from '../common/PointCircle'
import { TitleAndCaption } from "../common/TitleAndCaption";

import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"

import checkBox from "../../../images/recruit/icon_checkBox.svg"
import culture from "../../../images/recruit/culture.svg"

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: auto;

  text-align: center;
  box-sizing: border-box;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 0 16px;
  }
`

const TextContainer = styled.div`
  margin-top: 40px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 24px;
  }

  p {
    margin: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 32px;
    }
  }
`

const CircleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 40px;
`

const Circle = styled.img`
  width: 100%;
`

const BottomContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 160px auto 0;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 80px auto 0;
  }
`

const CheckContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  width: 100%;
  max-width: 960px;
  margin: 40px auto 0;

  background: #FFFFFF;
  padding: 40px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: column;
    gap: 24px;

    margin: 24px auto 0;
    padding: 24px;
  }

  & .recommended-text {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 45%;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      width: 100%;
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      margin: 0;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #222222;
    }
  }
`

const CheckPointList = [
  `発言しやすい環境の構築`,
  `肯定的な思考の浸透`,
  `働くうえでの不安原因を排除`,
  `相互理解を深めるコミュニケーション`,
  `情報のオープン化（共通認識）`,
  `希望・意見の尊重`,
  `自律駆動型の組織の推進`,
  `長所や成果を称える文化`,
]

export const CultureExplain: React.VFC = () => {
  return (
    <Wrapper>
      <TitleAndCaption title="ネクストライブの5つの文化" subTitle="CULTURE" color="#FFFFFF" />
        <TextContainer>
          <p>「おもしろい人たちとおもしろい時間を過ごしたい」というシンプルな想いが<br/>
          おのずと私たちのカルチャーをつくってきました。</p>
        </TextContainer>
      <CircleContainer>
        <Circle src={culture} alt="ネクストライブの5つの文化" />
      </CircleContainer>
      <BottomContainer>
        <TextContainer>
          <p>メンバーが共通して大切にしていることは、仕事を楽しむこと。<br />そのために、心理的安全性の高い職場環境の維持を心掛けています。</p>
        </TextContainer>
      </BottomContainer>
      <CheckContainer>
        {CheckPointList.map((item, idx) => {
          return(
            <div key={idx} className='recommended-text'>
              <img src={checkBox} alt="checkBox"/>
              <p>{item}</p>
            </div>
          )
        })}
      </CheckContainer>
    </Wrapper>
  )
}
