import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import styled from "styled-components"
import Img from 'gatsby-image';
import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst";
import { ogpUrl } from "../../../utlis/ImageUtil"

import { SectionTitle } from "../../common/SectionTitle"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { PickUpArticleList } from "../common/PickUpArticleList"

import { CultureExplain } from "./CultureExplain"
import { InHouseSystem } from "./InHouseSystem";

const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

const HeaderContainer = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
`

const CaltureContainer = styled.div`
  padding: 160px 0;
  box-sizing: border-box;
  background-color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 0;
  }
`

const InHouseSystemContainer = styled.div`
  background: #FFFFFF;
`

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 160px 20px;
  background-color: #E9E9E9;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 32px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const PickUpArticleListWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
`

type Props = {
  headerImg?: any;
  breadcrumbsData: BreadcrumbsData["values"];
}

export const Culture: React.FC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="カルチャー| 採用情報 | ネクストライブ株式会社"
        description="ネクストライブ株式会社のカルチャーの紹介ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.headerImg)}
      />
      <Wrapper>
        <SectionTitle title="CULTURE" subTitle="カルチャー"/>
        <HeaderContainer>
          <Img fluid={props.headerImg} alt="カルチャー"/>
        </HeaderContainer>
        <CaltureContainer>
          <CultureExplain />
        </CaltureContainer>
        <InHouseSystemContainer>
          <InHouseSystem />
        </InHouseSystemContainer>
        <BlogContainer>
          <PickUpArticleListWrapper>
            <PickUpArticleList/>
          </PickUpArticleListWrapper>
        </BlogContainer>
      </Wrapper>
    </Layout>
  )
}
